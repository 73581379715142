<template>
    <div>
        <PageHeaderLayout>
            <div class="main-page-content"
                 v-loading="loadingStatus"
                 :element-loading-text="loadingText">
                <el-row :gutter="48">
                    <el-form ref="articleForm" :model="formData" :rules="rules" label-width="80px">
                        <el-col :span="18" class="content-left">
                            <el-form-item label="标题" prop="title">
                                <el-input v-model="formData.title" maxlength="32" placeholder="请输入标题，标题长度不超过32个字符"></el-input>
                            </el-form-item>
                            <el-form-item label="文章来源" prop="source">
                                <el-input v-model="formData.source" placeholder="请输入文章来源" maxLength="32"></el-input>
                            </el-form-item>
                            <el-form-item label="摘要" prop="excerpt">
                                <el-input type="textarea" v-model="formData.excerpt" placeholder="请输入文章摘要"></el-input>
                            </el-form-item>
                            <el-form-item label="关键字" prop="keyword">
                                <div>
                                    <div class="el-col-14">
                                        <el-input type="text"
                                                  v-model="formData.keyword" placeholder="请输入关键字">
                                        </el-input>
                                    </div>
                                    <div class="el-col-9 margin-left-12 text-color-999">(两个关键字之间请用逗号隔开)</div>
                                </div>
                            </el-form-item>
                             <el-form-item label="序号" prop="order">
                               <div class="el-col-14">
                               <el-input v-model="formData.order" maxlength="5" onkeyup="value=value.replace(/\D/g,'')" @input="isChangeOrder" :disabled="orderDisabled" :placeholder="orderPlaceholder"></el-input>
                               </div>
                                 <div class="el-col-9 margin-left-12 text-color-999">(数值越低排序越靠前)</div>
                            </el-form-item>
                            <el-form-item>
                                <div class="row">
                                    <div class="el-col-3" v-for="(item,index) in  tagList" :key="index">
                                        <el-tag closable @close="onTagClose(item, index)" class="text-hidden">
                                            {{ item }}
                                        </el-tag>
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="内容" prop="content">
                                <ApeEditor :init-html="formData.content" :editorHeight="480"
                                           @handleTinymceInput="handleTinymceInput"></ApeEditor>
                            </el-form-item>
                        </el-col>
                        <el-col class="content-right" :span="6">
                            <el-form-item label="封面" prop="cover_img">
                                <ApeUploader :limit="1" @handleUploadSuccess="handleUploadSuccess"
                                             @handleUploadRemove="handleUploadRemove"
                                             :upload-file-list="uploadFileList"></ApeUploader>
                            </el-form-item>
                            <el-form-item label="缩略图" prop="thumb_img">
                                <ApeUploader :limit="1" @handleUploadSuccess="handleThumbUploadSuccess"
                                             @handleUploadRemove="handleThumbUploadRemove"
                                             :upload-file-list="uploadThumbFileList"></ApeUploader>
                            </el-form-item>
                            <el-form-item label="专题分类" prop="specialTopic">
                                <el-col style="margin-top: 12px">
                                    <el-tree
                                            :data="specialTopicData"
                                            show-checkbox
                                            default-expand-all
                                            :default-checked-keys="defaultCheckedKeys"
                                            node-key="id"
                                            ref="tree"
                                            @check-change="onTreeCheckChange"
                                            highlight-current
                                            style="z-index: 999"
                                            :props="defaultProps">
                                    </el-tree>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-button size="medium"
                                           type="primary"
                                           :disabled="loadingStatus"
                                           style="margin-left: 36px"
                                           @click="saveConfirm">
                                    保存文章
                                </el-button>
                                <el-button size="medium" class="margin-left-12" @click="returnList">返回</el-button>
                            </el-form-item>
                        </el-col>
                    </el-form>
                </el-row>
            </div>
        </PageHeaderLayout>
    </div>
</template>

<script>
    import PageHeaderLayout from '@/layouts/PageHeaderLayout'
    import ApeUploader from '@/components/ApeUploader'
    import ApeEditor from '@/components/ApeEditor'
    import {mapGetters} from 'vuex'
    import ModalDialog from '@/components/ModalDialog'

    export default {
        components: {
            PageHeaderLayout,
            ApeUploader,
            ApeEditor,
            ModalDialog,
        },
        data() {
            return {
              orderDisabled: false,
              total: '',
                activeTab: '0',
                hearTitle: '基本设置',
                loadingStatus: false,
                loadingText: '玩命提交中……',
                articleId: "", // 文章id，空值表示创建，非空值为编辑

                // 文章基本信息表单结构
                formData: {
                    cover_img:'',
                    thumb_img:''
                },
                // 已上传图片列表
                uploadFileList: [],
                //缩略图
                uploadThumbFileList: [],
                // 文章基本信息规则
                rules: {
                    title: [{required: true, message: '请输入文章标题', trigger: 'blur'}],
                    excerpt: [{required: true, message: '请输入文章摘要', trigger: 'blur'}],
                    cover_img: [
                        {required: true, message: '上传图片', trigger: 'blur', validator: this.apeUploaderVerify},
                    ],
                    thumb_img: [
                        {required: true, message: '上传图片', trigger: 'blur', validator: this.apeUploaderVerify},
                    ],
                    content:[
                        {required: true, message: '请输入内容', trigger: 'blur', validator: this.apeHtmlContentVerify},
                    ],
                  order: [{required: true, message: '请输入序号', trigger: 'blur'}],
                },

                tagList: [],

                defaultProps: {
                    children: 'category_list',
                    label: 'title'
                },

                //专题Id
                stCId: "",
                //文章id
                curArticleId: "",
                specialTopicData: [],
                defaultCheckedKeys: [],// 默认选中分类


            }
        },
        computed: {
            ...mapGetters(['userPermissions']),
          orderPlaceholder(){
            let placeholder = ""
            if ((this.total-1) < 0) {
              return placeholder
            }
            placeholder = '请输入0-' + (this.total-1) + '之间的数字'
              return placeholder
          }
        },
        methods: {
            // 返回列表
            returnList() {
                this.$router.push('/article_list')
            },

            // 确认保存按钮
            async saveConfirm() {
                // 调用组件的数据验证方法
                // this.dialogData.visible = true;
                this.$refs['articleForm'].validate((valid) => {
                    if (valid) {
                        this.formSubmit()
                    } else {
                        this.$message.error('数据验证失败，请检查必填项数据！')
                    }
                });
                return true
            },

            // 文章相关信息的保存处理
            async formSubmit() {
                this.loadingStatus = true;

                this.formData.category_list = this.specialTopicData;

                let id = await this.$api.saveArticle(this.formData);
                if (id) {
                    this.$notify.success('文章保存成功！');
                    this.curArticleId = id;
                    this.resetFormData();
                    let data  =  await this.$api.getArticleCategoryList();
                    this.specialTopicData = data;
                }
                // if (this.articleId && id) {
                    this.$router.push(this.$route.matched[1].path)
                // }
                this.$nextTick(() => {
                    this.loadingStatus = false;
                })
            },

            // 图片上传成功回调
            handleUploadSuccess(file, fileList) {
                this.formData.cover_img = file.id;
                this.uploadFileList = fileList
            },

            // 图片删除回调
            handleUploadRemove(file, fileList) {
                this.formData.cover_img = "";
                this.uploadFileList = fileList;
                this.formData.cover_info = {};
            },

            //缩略图
            handleThumbUploadSuccess(file, fileList) {
                this.formData.thumb_img = file.id;
                this.uploadThumbFileList = fileList
            },

            // 缩略图删除回调
            handleThumbUploadRemove(file, fileList) {
                this.formData.thumb_img = "";
                this.uploadThumbFileList = fileList;
                this.formData.thumb_info = {};
            },

            // 图片上传自定义验证
            apeUploaderVerify(rule, value, callback) {
                if (rule.required && !this.formData.cover_img && !this.formData.thumb_img) {
                    callback(new Error(rule.message))
                }
                callback()
            },

            // 处理编辑器内容输入
            handleTinymceInput(val) {
                this.formData.content = val
            },

            // 初始化文章基本信息
            async initArticleForm() {
                if (this.$route.params.article_id) {
                    this.articleId = this.$route.params.article_id;
                }

                if (this.articleId) {
                    let {info} = await this.$api.getArticleInfo(this.articleId);
                    this.formData = info;

                    if (info.cover_img) {
                        this.uploadFileList = [{
                            id: info.cover_info.id,
                            name: info.cover_info.title,
                            url: info.cover_info.full_path
                        }]
                    }

                    if (info.thumb_img) {
                        this.uploadThumbFileList = [{
                            id: info.thumb_info.id,
                            name: info.thumb_info.title,
                            url: info.thumb_info.full_path
                        }]
                    }

                    this.specialTopicData = info.category_list;

                    for(let item of this.specialTopicData){
                        if(item.category_list && item.category_list.length > 0){
                            for(let category of item.category_list){
                                if(category.checked){
                                    this.defaultCheckedKeys.push(category.id);
                                }
                            }
                        }
                    }

                    this.curArticleId = this.articleId;
                }else {
                    let data  =  await this.$api.getArticleCategoryList();
                    this.specialTopicData = data;
                }
            },

            //还原表单
            resetFormData() {
                this.$refs['articleForm'].resetFields();
                this.formData = {};
                this.uploadFileList = [];
                this.uploadThumbFileList = [];
                this.tagList = [];
                this.defaultCheckedKeys = [];
            },

            //关闭标签
            onTagClose(item, index) {
                this.tagList.splice(index, 1);
                this.formData.keyword = this.tagList.join(",");

            },

            //获取专题
            async getSpecialTopicList() {
                this.loadingStatus = true;
                let pageData = {
                    page_size: 1000,
                    current_page: 1
                };
                let {list, pages} = await this.$api.getSpecialTopicList(pageData);

                if (list.length > 0) {
                    for (let specialTopic of list) {
                        let id = specialTopic.id;
                        let object = {
                            id: specialTopic.id,
                            label: specialTopic.title
                        };
                        this.getSpecialTopicCategoryList(id, object)
                    }
                }

                this.dialogData.visible = false;
            },

            //获取专题分类
            async getSpecialTopicCategoryList(id, specialTopic) {
                let pageData = {
                    page_size: 1000,
                    current_page: 1
                };

                if (id) {
                    pageData.st_id = id;
                }

                let {list, pages} = await this.$api.getSpecialTopicCategoryList(pageData);

                if (list.length > 0) {
                    let specialTopicCategory = [];
                    for (let category of list) {
                        let object = {
                            id: category.id,
                            label: category.title
                        };
                        specialTopicCategory.push(object)
                    }
                    specialTopic.children = specialTopicCategory;
                    this.specialTopicData.push(specialTopic);
                }
            },

            //树形节点点击
            onTreeCheckChange(data,isChecked, leafCheck) {
                if(data.hasOwnProperty('checked')){
                    data.checked = isChecked;
                }
            },


            //文章内容上传自定义验证
            apeHtmlContentVerify(rule, value, callback) {
                if (rule.required && !this.formData.content) {
                    callback(new Error(rule.message))
                }
                callback()
            },

          isChangeOrder(e) {
            let val = parseInt(e)
            if(!val) {
              return
            }
            this.$set(this.formData, "order", val)
            let newVal = ""
            if (val <= 0) {
              newVal = 0
              this.$set(this.formData, "order", newVal)
            } else if (val >= this.total) {
              newVal = (this.total - 1)
              this.$set(this.formData, "order", newVal)
            }
          }

        },

        async mounted() {
            this.loadingStatus = true;
            this.initArticleForm();
          if (this.$route.params.total) {
            this.total = this.$route.params.total;
          }
            this.$nextTick(() => {
                this.loadingStatus = false
            })
        },

        watch: {
            'formData.keyword'(newVal) {
                if (newVal) {
                    this.tagList = newVal.split('，');
                }
            },
          total: {
              handler(val) {
                if(val <= 0) {
                  this.orderDisabled = true
                  this.$set(this.formData, 'order', 0)
                } else {
                  this.orderDisabled = false
                }
              }
          }
        }

    }
</script>

<style lang="stylus" scoped>
    .content-left
        min-height 640px
        border-right 1px solid #e8e8e8
        .el-cascader
            display block
            width 320px

    .content-right
        .right-button
            border-top 1px solid #e8e8e8e8
            padding 12px 12px
        .el-form-item__label
            float none
            display inline-block
            text-align left
            padding 0 0 10px 0
        .el-form-item__content
            margin-left 10px
            .el-checkbox-group
                margin-top -12px

    .margin-left-12
        margin-left 14px

    .text-color-999
        color #999

    .text-hidden
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
</style>
